import React from "react"
import styled from 'styled-components'
import {signupUrl} from "../../../utils/urlHelper";

const Sa = styled.a`
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
`;

const SBlock = styled.div`
  overflow: hidden;
  position: relative;
  &:after {
    position: absolute;
    top: -210px;
    right: -200px;
    display: block;
    content: "";
    width: 970px;
    height: 310px;
    opacity: .5;
    transform: rotate(
    -18deg
    );
        background-image: linear-gradient(
    -19deg
    ,#21d4fd,#b721ff);
        border-radius: 90px;
        z-index: 1;
    }
`

const BlogCta = (props) => {
  const { cta, body, source, term, link } = props;
  let url =  (link) ? link : signupUrl({sb_source: source, sb_term: term});
  return (
    <Sa href={url} className="my-12 relative block">
      <SBlock className="bg-primary p-12 block rounded-xl hover:shadow-xl transition transform hover:-translate-y-3">
        <div className="relative z-10">
          <p className="text-3xl text-white font-bold no-underline">{body}</p>
          <p className="text-1xl text-white no-underline">{cta}</p>
        </div>
      </SBlock>
    </Sa>
  )
}

export default BlogCta
