import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import {Layout} from "components/layout"
import { Section,  Container, Row } from "components/ui"
import { GetStartedSection } from "components/sections"
import SEO from "components/seo"
import tw from "tailwind.macro"
import styled from 'styled-components'
import {theme} from '../../tailwind.config'
import "styles/prism-twilight.css"
import "styles/pages/markdown.scss"
import BlogCta from "components/ui/cta/BlogCta"

const shortcodes = { BlogCta };

const SPostBody = styled.div`
    ${tw`text-text text-lg`}
    
    .markdown-body h2 {
        ${tw`text-2xl`}
    }

  @media(max-width: ${theme.extend.screenSizes.md.max}) {
    > blockquote { 
      margin-left: 0px;
    }
  }
`

export default function Template(props) {
    let { data: {site: { siteMetadata: { url, twitterHandle } },  post } } = props;

    return (
        <Layout>
            <SEO
                title={post.frontmatter.metaTitle}
                description={post.frontmatter.metaDescription}
                image={post.frontmatter.thumbnail}
                author="Laurent Lemaire"
            />
            <Section color="alt" className="casestudy">
                <Container>
                    <Row>
                        <div className="flex flex-col gap-4 md:flex-row md:gap-20 md:mx-24">
                            <div className="md:w-3/4">
                                <h1 className="font-heading tracking-tight text-4xl md:text-5xl md:leading-tight font-black">
                                    "{post.frontmatter.userQuote}"
                                </h1>
                                <p className="mt-6 whitespace-b text-md">
                                    {post.frontmatter.companyDescription}
                                </p>
                            </div>
                            <div className="w-72 m-auto justify-center flex items-center">
                                <img className="rounded-xl shadow-lg" alt={post.frontmatter.title} src={post.frontmatter.userPhoto}/>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Section>

            <Section>
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <div className="flex flex-col md:flex-row gap-x-16">
                                <div className="md:order-1 order-2 md:w-1/4 md:p-0 md:p-16 p-8 text-sm flex flex-col gap-y-4 ">
                                    <div>
                                        <span className="block text-primary uppercase mb-1">Company</span>
                                        <img className="w-24" alt={post.frontmatter.title} src={post.frontmatter.companyLogo}/>
                                    </div>
                                    <div>
                                        <span className="block text-primary uppercase mb-1">{post.frontmatter.companyName}</span>
                                        <p>{post.frontmatter.companyShortDescription}</p>
                                    </div>
                                    <div>
                                        <span className="block text-primary uppercase mb-1">Company size</span>
                                        <p>{post.frontmatter.companySize}</p>
                                    </div>
                                    <div>
                                        <span className="block text-primary uppercase mb-1">Headquarters</span>
                                        <p>{post.frontmatter.companyHq}</p>
                                    </div>
                                </div>
                                <SPostBody className="md:order-2 order-1 md:w-3/4 md:-mt-52 md:p-16 p-8 bg-white rounded-xl -mt-28">
                                    <div className="flex">
                                        <div className="markdown-body">
                                            <MDXProvider components={shortcodes}>
                                                <MDXRenderer>{ post.body }</MDXRenderer>
                                            </MDXProvider>
                                        </div>
                                    </div>
                                </SPostBody>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Section>
            <GetStartedSection/>
        </Layout>
)
}

export const postQuery = graphql`
  query CaseStudySlug($id: String) {
    site: site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    post: mdx(id: { eq: $id}) {
      frontmatter {
        title
        author
        layout
        date(formatString: "MMMM Do, YYYY")
        thumbnail
        rating
        metaDescription,
        metaTitle
        userName
        userPhoto
        userQuote
        companyLogo
        companyShortDescription
        companyDescription
        companyName
        companySize
        companyHq
      }
      fields {
        slug
      }
      body
    }
  }
`
